import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
  createEntityStore,
} from "@datorama/akita";
import { Site } from "./interface";

export const SITE_STORE = "site_store";
export const KEY = "id";

export interface SiteState extends EntityState<Site, string>, ActiveState {}

@StoreConfig({
  idKey: KEY,
  name: SITE_STORE,
  resettable: true,
})
export class SiteStore extends EntityStore<SiteState> {}

export const siteStore = new SiteStore();
